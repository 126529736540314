import { SwiperSlide, Swiper } from "swiper/react"
import { UseMediaQueries } from "../utilities/hook"
import { motion } from "framer-motion"
import { Pagination, Autoplay } from 'swiper/modules';

export function HomeProducts({ photo, name, wa_link }) {

    const { md, lg } = UseMediaQueries()

    if (lg) {
        return (
            <motion.div
                whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                }}
                className="w-[30vw] cursor-pointer">
                <div className="flex flex-col gap-4 p-5 bg-amber-400 rounded-2xl">
                    <div className="rounded-xl overflow-hidden">
                        <Swiper autoplay={{ delay: 5000, disableOnInteraction: false, }} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                            {
                                photo ?
                                    photo.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img alt={`Image ${index + 1}`} src={'https://api.bakentake.id' + image.url} className='w-full h-full object-cover' />
                                        </SwiperSlide>
                                    ))
                                    : <></>
                            }
                        </Swiper>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="font-luckiest text-white text-[1.5vw]">{name}</div>
                        <button className="font-luckiest text-white text-[1.5vw] px-4 py-2 bg-amber-950 rounded-full"><a href={wa_link} target="_blank">ORDER ONLINE</a></button>
                    </div>
                </div>
            </motion.div>
        )
    }

    if (md) {
        return (
            <div className="w-full">
                <div className="flex flex-col gap-5 p-5 bg-amber-400 rounded-3xl">
                    <div className="rounded-3xl overflow-hidden">
                        <Swiper autoplay={{ delay: 5000, disableOnInteraction: false, }} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                            {
                                photo ?
                                    photo.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img alt={`Image ${index + 1}`} src={'https://api.bakentake.id' + image.url} className='w-full h-full object-cover' />
                                        </SwiperSlide>
                                    ))
                                    : <></>
                            }
                        </Swiper>
                    </div>
                    <div className="flex justify-between items-center py-2">
                        <div className="font-luckiest text-white text-2xl">{name}</div>
                        <button className="font-luckiest text-white text-xl px-4 py-2 bg-amber-950 rounded-full"><a href={wa_link} target="_blank">ORDER ONLINE</a></button>
                    </div>
                </div>
            </div>
        )
    }
}
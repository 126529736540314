import roti_pisang from '../assets/roti_pisang.png'
import diamond_blue from '../assets/diamond_blue.png'
import diamond_yellow from '../assets/diamond_yellow.png'
import circle_orange from '../assets/circle_orange.png'
import red_bubble from '../assets/red_bubble.png'
import yellow_star from '../assets/yellow_star.png'
import { UseMediaQueries } from '../utilities/hook'
import { useInView, motion } from 'framer-motion'
import { useRef } from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules';
import { desc } from 'framer-motion/client'

export function RightImageCard({title, photo, description, subtitle, link, wa_link}){

    const { md, lg } = UseMediaQueries()
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    if(lg){
        return (
            <div className="flex w-[95%] gap-10 justify-center items-center m-auto py-[4vw] overflow-hidden">
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} 
                    className="flex flex-col w-[50%] gap-10">
                    <div className='flex  justify-start w-[100%]'>
                        <img className='relative top-0 w-[3vw] h-full' src={diamond_yellow}></img>
                        <img className='relative top-10 w-[2vw] h-full' src={diamond_blue}></img>
                        <img className='relative right-4 top-0 w-[1.5vw] h-[1.5vw]' src={circle_orange}></img>
                    </div>
                    <div className="font-luckiest flex flex-col justify-start items-start">
                        <span className="text-brown text-[2.2vw] leading-none">
                            {subtitle}
                        </span>
                        <span className="text-blue text-[5vw] leading-none">
                            {title}
                        </span>
                    </div>
                    <div className="text-[1.2vw] font-poppins">
                        {description}
                    </div>
                    <div>
                        <button className="bg-yellow font-luckiest text-blue px-[2vw] text-[1.2vw] py-1 rounded-full">
                            {
                                link ?
                                <a href={link} target='_blank'>CHECK OUT MEMORIES</a> : <a href={wa_link} target='_blank'>BOOK YOUR MEMORIES WITH US</a>
                            }
                        </button>
                    </div>
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1, rotate: 6 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} 
                    className="w-[40%] h-[35vw] rotate-6 rounded-[3vw] overflow-hidden">
                    <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                            {
                                photo && photo.length > 0 ? (
                                    photo.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={'https://api.bakentake.id' + image.url}
                                                className='w-full h-full object-cover'
                                                alt={`Image ${index + 1}`}
                                            />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p className="text-center">No images available</p>
                                )
                            }
                        </Swiper>
                </motion.div>
            </div>
        )
    }

    if(md){
        return (
            <div className="flex flex-col w-full gap-4 justify-center items-center m-auto mt-8 py-4">
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1, rotate: 6 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }}   className="w-[80vw] h-[80vw]  rotate-6 rounded-[3vw] overflow-hidden">
                    <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                            {
                                photo && photo.length > 0 ? (
                                    photo.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={'https://api.bakentake.id' + image.url}
                                                className='w-full h-full object-cover'
                                                alt={`Image ${index + 1}`}
                                            />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p className="text-center">No images available</p>
                                )
                            }
                        </Swiper>
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} 
                    className="flex flex-col w-full gap-4 px-4">
                    <div className="font-luckiest flex flex-col justify-start items-start">
                        <span className="text-brown text-[7vw]">
                            {subtitle}
                        </span>
                        <span className="text-blue text-[9vw]">
                            {title}
                        </span>
                    </div>
                    <div className="text-[3vw] font-poppins">
                            {description}
                    </div>
                    <div className='w-full flex justify-center items-center'>
                        <button className="bg-yellow font-luckiest text-blue w-full text-[4vw] py-2 rounded-full">
                            {
                                link ?
                                <a href={link} target='_blank'>CHECK OUT MEMORIES</a> : <a href={wa_link} target='_blank'>BOOK YOUR MEMORIES WITH US</a>
                            }
                        </button>
                    </div>
                </motion.div>
                
            </div>
        )
    }
}

export function LeftImageCard({title, photo, description, subtitle, link, wa_link}){
    const { md, lg } = UseMediaQueries()
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    if(lg){
        return (
            <div className="flex w-[95%] gap-10 justify-evenly items-center m-auto py-[4vw] overflow-hidden">
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1, rotate: -6 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} className="w-[45%] h-[40vw] -rotate-6 rounded-[3vw] overflow-hidden">
                    <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                        {
                            photo && photo.length > 0 ? (
                                photo.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            src={'https://api.bakentake.id' + image.url}
                                            className='w-full h-full object-cover'
                                            alt={`Image ${index + 1}`}
                                        />
                                    </SwiperSlide>
                                ))
                            ) : (
                                <p className="text-center">No images available</p>
                            )
                        }
                    </Swiper>
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }}
                    className="flex flex-col w-[45%] gap-10 ">
                   
                    <div className="font-luckiest flex flex-col justify-start items-start">
                        <span className="text-brown text-[2.2vw] leading-none">
                            {subtitle}
                        </span>
                        <span className="text-blue text-[5vw] leading-none">
                            {title}
                        </span>
                    </div>
                    <div className="text-[1.2vw] font-poppins">
                        {description}
                    </div>
                    <div>
                        <button className="font-luckiest text-blue px-[2vw] text-[1.5vw] py-1 rounded-full bg-yellow">
                            {
                                link ?
                                <a href={link} target='_blank'>CHECK OUT MEMORIES</a> : <a href={wa_link} target='_blank'>BOOK YOUR MEMORIES WITH US</a>
                            }
                        </button>
                    </div>
                    <div className='flex w-[100%] items-end flex-col '>
                        <img className='relative top-0 w-[5vw] right-0' src={red_bubble}></img>
                        <img className='relative w-[5vw] right-0' src={yellow_star}></img>
                    </div>
                </motion.div>
                
            </div>
        )
    }

    if(md){
        return (
            <div className="flex flex-col w-full gap-10 justify-evenly items-center m-auto py-4">
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1, rotate: -6 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} className="w-[80vw] h-[80vw -rotate-6 rounded-[3vw] overflow-hidden">
                    <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                            {
                                photo && photo.length > 0 ? (
                                    photo.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={'https://api.bakentake.id' + image.url}
                                                className='w-full h-full object-cover'
                                                alt={`Image ${index + 1}`}
                                            />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p className="text-center">No images available</p>
                                )
                            }
                        </Swiper>
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ scale: 2, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} className="flex flex-col w-full gap-4 px-4">
                   
                    <div className="font-luckiest flex flex-col justify-start items-start">
                        <span className="text-brown text-[8vw]">
                            {subtitle}
                        </span>
                        <span className="text-blue text-[10vw]">
                            {title}
                        </span>
                    </div>
                    <div className="text-[4vw] font-poppins">
                        {description}
                    </div>
                    <div className='flex justify-center items-center '>
                        <button className="font-luckiest text-white text-2xl py-2 rounded-full w-[50%]">
                            {
                                link ?
                                <a href={link} target='_blank'>CHECK OUT MEMORIES</a> :<a href={wa_link} target='_blank'>BOOK YOUR MEMORIES WITH US</a>
                            }
                        </button>
                        <div className='flex w-[100%] items-end flex-col '>
                            <img className='relative top-0 w-[8vw] right-2' src={red_bubble}></img>
                            <img className='relative w-[8vw] right-2' src={yellow_star}></img>
                        </div>
                    </div>
                    
                </motion.div>
                
            </div>
        )
    }
}

export function ProductCard({ name, photo_links, wa_link }){
    const { md, lg } = UseMediaQueries()

    if(lg){
        return (
            <div className='p-4 flex flex-col bg-light_orange rounded-2xl gap-10'>
                <div className='w-[100%] h-[26vw] rounded-2xl overflow-hidden'>
                    <Swiper autoplay={{ delay: 5000, disableOnInteraction: false, }} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                    {
                        photo_links ?
                            photo_links.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img alt={`Image ${index + 1}`} src={'https://api.bakentake.id' + image.url} className='w-full h-full object-cover' />
                                </SwiperSlide>
                            ))
                            :<></>
                    }
                    </Swiper>
                </div>
                <div className='flex justify-between items-center font-luckiest '>
                    <div className='text-white text-xl'>
                        {name}
                    </div>
                    <div className='bg-maroon text-white p-2 rounded-full text-md '>
                        <a a href={wa_link} target='_blank'>ORDER ONLINE</a>
                    </div>
                </div>
            </div>
        )
    }

    if(md){
        return (
            <div className='p-4 flex flex-col bg-light_orange rounded-2xl gap-4 w-full'>
                <div className='w-full h-[80vw] rounded-2xl overflow-hidden'>
                    <Swiper autoplay={{ delay: 5000, disableOnInteraction: false, }} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                        {
                            photo_links ?
                                photo_links.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img alt={`Image ${index + 1}`} src={'https://api.bakentake.id' + image.url} className='w-full h-full object-cover' />
                                    </SwiperSlide>
                                ))
                                : <></>
                        }
                    </Swiper>
                </div>
                <div className='flex justify-between items-center font-luckiest '>
                    <div className='text-white text-xl'>
                        {name}
                    </div>
                    <div className='bg-maroon text-white p-2 rounded-full text-md '>
                        <a a href={wa_link} target='_blank'>ORDER ONLINE</a>
                    </div>
                </div>
            </div>
        )
    }
}
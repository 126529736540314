import { LeftImageCard, RightImageCard } from "../../components/Card";
import separator from '../../assets/separator.png'

export default function Event({events, wa_link}){
    return(
        <div className="flex flex-col justify-center items-center w-full h-full">
            {
                events ?
                events.map((event, index) => (
                    index % 2 === 0 ? (
                        <RightImageCard
                            key={index}
                            title={event.name}
                            description={event.description}
                            photo={event.photo}
                            subtitle={event.subtitle}
                            link={event.link}
                            wa_link={wa_link}
                        />
                    ) : (
                        <LeftImageCard
                            key={index}
                            title={event.name}
                            description={event.description}
                            photo={event.photo}
                            subtitle={event.subtitle}
                            link={event.link}
                            wa_link={wa_link}
                        />
                    )
                ))
                 : 
                <></>
            }
        </div>
    )
}
import axios from 'axios';

async function apiHandler(url) {
    try {
        const response = await axios.get('https://api.bakentake.id/api/' + url + '?populate=*');
       
        return response.data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiHandlerCustom(url) {
    try {
        const response = await axios.get('https://api.bakentake.id/api/' + url);

        return response.data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export { apiHandler, apiHandlerCustom };

import { ProductCard } from "../../components/Card";
import { UseMediaQueries } from "../../utilities/hook";

export default function ProductLists({data, wa_link}){
    const { md, lg } = UseMediaQueries()

    if(lg){
        return (
            <div className="flex flex-col justify-center items-center gap-10 my-[4vw]"> 
                <div className="flex flex-col justify-center items-center gap-8">
                    <div className="text-blue font-luckiest text-6xl text-center">
                        TREAT YOURSELF TO OUR FRESHLY BAKED GOODNESS
                    </div>
                    <div className="font-poppins w-[70%] text-center">
                        Enjoy our selection of fresh-baked breads and pastries, made with care and the best ingredients. From soft breads to tasty treats, there's something for everyone. Find your new favorite today!
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-8 w-[95%] ">
                    {data.map((product) => {
                        return (
                            <ProductCard
                                key={product.id}
                                name={product.name}
                                photo_links={product.photo}
                                wa_link={wa_link}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    if(md){
        return (
            <div className="flex flex-col justify-center items-center gap-4 mt-8"> 
                <div className="flex flex-col justify-center items-center gap-4">
                    <div className="text-blue font-luckiest text-[12vw]  text-center leading-none">
                        TREAT YOURSELF TO OUR FRESHLY BAKED GOODNESS
                    </div>
                    <div className="font-poppins w-[100%] text-center leading-normal text-[5vw]">
                        Enjoy our selection of fresh-baked breads and pastries, made with care and the best ingredients. From soft breads to tasty treats, there's something for everyone. Find your new favorite today!
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center gap-4 w-full px-4">
                    {data.map((product) => {
                        return (
                            <ProductCard
                                key={product.id}
                                name={product.name}
                                photo_links={product.photo}
                                wa_link={wa_link}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
    
}
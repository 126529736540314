import { LeftImageCard, RightImageCard } from "../../components/Card";
import NavigationBar from "../../components/Navbar";
import Showcase from "./1_Showcase";
import Description from "./2_Description";
import Follow from "./3_Follow";
import Footer, { FooterWithoutMap } from "../../components/Footer"
import { useEffect, useState } from "react";
import { apiHandler } from "../../utilities/apiHandler";
import Event from "./4_Event";
import { footer } from "framer-motion/client";

export default function About(){

    const [data, setData] = useState({})
    const [events, setEvents] = useState(null)
    const [footerData, setFooterData] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const api = await apiHandler('about')
            const eventsData = await apiHandler('events')
            const footerData = await apiHandler('footer')
       
            const { wa_link } = footerData
            const { description, gallery, instagram, photo_update } = api
            setData({ description, gallery, instagram, photo_update })
            setEvents(eventsData)
            setFooterData({ wa_link })
        }

        fetchData()
    }, [])

    return (

        <div className="flex flex-col max-w-[100vw]  overflow-y-hidden">
            <NavigationBar/>
            {
                data.gallery ?
                    <Showcase photo={data.gallery}/> : <></>
            }
            <Description/>
           {
            events ?
            <Event events={events} wa_link={footerData.wa_link}/> : <></>
           }
            {
                data.photo_update ?
                    <Follow photo={data.photo_update} instagram={data.instagram}/> : <></>
            }
            <FooterWithoutMap/>
        </div>
    )
}
import { useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import roti_pisang from '../assets/roti_pisang.png'
import { UseMediaQueries } from '../utilities/hook'
import { apiHandler } from '../utilities/apiHandler'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

export default function Footer() {

    const { md, lg } = UseMediaQueries()
    const [data, setData] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            const api = await apiHandler('footer')
            const { email, instagram, link_map, open_hours, phone_number, photo, description, wa_link, instagram_link } = api
            setData({ email, instagram, link_map, open_hours, phone_number, photo, description, wa_link, instagram_link })
        }

        fetchData()
    }, [])

    if (lg) {
        return (
            <div className="flex flex-col gap-16 py-[4vw] bg-cream pt-8">
                <div className="flex w-[85%] m-auto">
                    <div className="flex flex-col w-1/2 ">
                        <div className="bg-orange flex flex-col gap-8 p-4 border-black border-4 rounded-tl-2xl">
                            <div className="font-luckiest text-[6vw] text-white">
                                FIND US AT
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="font-luckiest text-[1.5vw] text-white">
                                    email us
                                </div>
                                <div>
                                    <a href={`mailto:${data.email}`} className="font-poppins text-[1.2vw] text-white hover:text-blue">
                                        {data.email}
                                    </a>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="font-luckiest text-[1.5vw] text-white">
                                    call us
                                </div>
                                <div className="font-poppins text-[1.2vw] text-white">
                                    <a href={data.wa_link} target='_blank' className='hover:text-blue'>{data.phone_number}</a>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="font-luckiest text-[1.5vw] text-white">
                                    address
                                </div>
                                <div className="font-poppins text-[1.2vw] text-white">
                                    Jl Taman Srigunting 10,
                                    Kawasan Kota Lama,
                                    Semarang
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="w-1/2  border-black border-x-4 border-b-4 rounded-bl-2xl">
                                <div className="flex flex-col gap-4 px-[4vw] py-[4vw]">
                                    <div className="font-luckiest text-[1.5vw] leading-none">
                                        open hours
                                    </div>
                                    <div className="font-poppins text-[1vw] font-bold leading-none">
                                        Monday - Thursday
                                    </div>
                                    <div className="font-poppins text-[1.2vw] leading-none">
                                        {data.open_hours}
                                    </div>
                                    <div className="font-poppins text-[1vw] font-bold leading-none">
                                        Friday - Saturday
                                    </div>
                                    <div className="font-poppins text-[1.2vw] leading-none">
                                        09.00 - 21.00
                                    </div>

                                </div>
                            </div>

                            <div className="w-1/2  border-black border-r-4 border-b-4">
                                <div className="flex flex-col gap-4 px-[4vw] py-[4vw]">
                                    <div className="font-luckiest text-[1.4vw]">
                                        our instagram
                                    </div>
                                    <div className="font-poppins text-[1vw]">
                                        <a href={data.instagram_link} target='_blank'>{data.instagram}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 bg-sky-400 border-y-4 border-r-4 border-black rounded-r-2xl overflow-hidden">
                        <div style={{ width: '100%', height: '100%' }}>
                            <iframe
                                title="Google Maps"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Taman%20Srigunting%20No.10,%20Tj.%20Mas,%20Kec.%20Semarang%20Utara,%20Kota%20Semarang,%20Jawa%20Tengah%2050174&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex w-[95%] text-white gap-4 m-auto h-[27vw]">
                    <div className="p-[4vw] w-[60%] rounded-[2vw] bg-maroon">
                        <div className="flex flex-col gap-16">
                            <div className="flex justify-between items-center">
                                <div className="flex flex-col gap-8">
                                    <div className='w-[6vw] h-[6vw] '>
                                        <img src={logo} className='w-full object-cover'></img>
                                    </div>
                                    <div className='font-poppins w-[60%]'>
                                        {data.description}
                                    </div>
                                </div>
                                <div className="flex flex-col text-[1.5vw] gap-4 font-luckiest">
                                    <div className=''>
                                        <a href='/'>home</a>
                                    </div>
                                    <div className=''>
                                        <a href='/about'>about</a>
                                    </div>
                                    <div className=''>
                                        <a href='/products'>product</a>
                                    </div>
                                    <div className='font-luckiest'>
                                        <a href={data.wa_link} target='_blank'>ORDER</a>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center text-white">
                                <div className='flex gap-4 '>
                                    <div className='font-luckiest'>
                                        COPYRIGHT
                                    </div>
                                    <div className='font-luckiest'>
                                        &copy;
                                    </div>
                                    <div className='font-luckiest'>
                                        BAKENTAKE
                                    </div>
                                </div>
                                <div className='flex gap-4'>
                                    <div className='font-luckiest'>
                                        TERMS OF SERVICE
                                    </div>
                                    <div className='font-luckiest'>
                                        |
                                    </div>
                                    <div className='font-luckiest'>
                                        PRIVACY POLICY
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-amber-200 w-[40%] h-full rounded-[2vw] border-maroon border-[4px] overflow-hidden">
                        <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                            {
                                data.photo && data.photo.length > 0 ? (
                                    data.photo.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={'https://api.bakentake.id' + image.url}
                                                className='w-full h-full object-cover'
                                                alt={`Image ${index + 1}`}
                                            />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p className="text-center">No images available</p>
                                )
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        )
    }

    if (md) {
        return (
            <div className='bg-cream flex flex-col'>
                <div className='flex flex-col justify-center items-center mx-4 h-full my-8 gap-6'>
                    <div className='flex flex-col justify-center items-center font-luckiest w-full h-full '>
                        <div className='flex flex-col justify-start items-center text-white bg-orange w-full gap-6 h-[85vw] px-6 border-black border-4 rounded-tl-2xl'>
                            <div className='text-[7vw] w-full'>
                                FIND US AT
                            </div>
                            <div className='w-full flex flex-col gap-4'>
                                <div className='w-full text-[4vw] flex flex-col gap-4'>
                                    <div className=''>
                                        email us
                                    </div>
                                    <a href={`mailto:${data.email}`} className="font-poppins">
                                        {data.email}
                                    </a>
                                </div>
                                <div className='w-full text-[4vw] flex flex-col gap-4'>
                                    <div className=''>
                                        call us
                                    </div>
                                    <div className='font-poppins'>
                                        <a href={data.wa_link} target='_blank'>{data.phone_number}</a>
                                    </div>
                                </div>
                                <div className='w-full text-[4vw] flex flex-col gap-4'>
                                    <div className=''>
                                        address
                                    </div>
                                    <div className='font-poppins'>
                                        Jl Taman Srigunting 10,
                                        Kawasan Kota Lama,
                                        Semarang
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col justify-center items-start w-full h-[65vw] bg-cream px-6 border-black border-x-4 border-b-4 '>
                            <div className='w-full text-[5vw]'>
                                open hours
                            </div>
                            <div className="font-poppins text-[4.8vw] font-bold ">
                                Monday - Thursday
                            </div>
                            <div className="font-poppins text-[5vw] ">
                                {data.open_hours}
                            </div>
                            <div className="font-poppins text-[4.8vw] font-bold ">
                                Friday - Saturday
                            </div>
                            <div className="font-poppins text-[5vw] ">
                                09.00 - 21.00
                            </div>
                        </div>

                        <div className='flex flex-col justify-center items-start w-full h-[65vw] bg-cream px-6 border-black border-x-4 border-b-4'>
                            <div className='w-full text-[5vw]'>
                                OUR INSTAGRAM
                            </div>
                            <div className='font-poppins w-full text-[5vw]'>
                                <a href={data.instagram_link} target='_blank'>{data.instagram}</a>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center items-center h-[85vw] w-full mb-10 border-black border-4 rounded-r-2xl overflow-hidden'>
                        <div style={{ width: '100%', height: '100%' }}>
                            <iframe
                                title="Google Maps"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Taman%20Srigunting%20No.10,%20Tj.%20Mas,%20Kec.%20Semarang%20Utara,%20Kota%20Semarang,%20Jawa%20Tengah%2050174&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col justify-center items-center bg-maroon text-white gap-4 px-4 py-4'>
                    <div className='flex justify-start w-full'>
                        <div className='h-[20vw]'>
                            <img className='w-full h-full object-contain ' src={logo}></img>
                        </div>
                    </div>

                    <div className='flex flex-col gap-10'>
                        <div className='flex flex-col justify-center items-center gap-4'>
                            <div className='font-poppins text-[4vw]'>
                                {data.description}
                            </div>

                            <div className='flex justify-between items-center gap-8 font-luckiest w-full'>
                                <div className='text-[4vw]'>
                                    <a href='/'>home</a>
                                </div>
                                <div className='text-[4vw]'>
                                    <a href='/about'>about</a>
                                </div>
                                <div className='text-[4vw]'>
                                    <a href='/products'>product</a>
                                </div>
                                <div className='text-[4vw]'>
                                    <a href={data.wa_link} target='_blank'>ORDER</a>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col'>
                            <div className='font-luckiest text-[4vw] flex justify-start items-center gap-4'>
                                <div>
                                    copyright
                                </div>
                                <div className='text-[6vw]'>
                                    &copy;
                                </div>
                                <div>
                                    bakentake
                                </div>
                            </div>
                            <div className='font-luckiest text-[4vw] flex justify-start items-center gap-4'>
                                <div>
                                    terms of service
                                </div>
                                <div>
                                    |
                                </div>
                                <div>
                                    privacy policy
                                </div>
                            </div>
                        </div>

                        <div className='h-[80vw] w-[95vw] rounded-3xl overflow-hidden'>
                            <Swiper pagination={true} modules={[Pagination]} className="mySwiper w-full h-full">
                                {
                                    data.photo && data.photo.length > 0 ? (
                                        data.photo.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={'https://api.bakentake.id' + image.url}
                                                    className='w-full h-full object-cover'
                                                    alt={`Image ${index + 1}`}
                                                />
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        <p className="text-center">No images available</p>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export function FooterWithoutMap() {

    const { md, lg } = UseMediaQueries()
    const [data, setData] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            const api = await apiHandler('footer')

            const { email, instagram, link_map, open_hours, phone_number, photo, description, wa_link, instagram_link } = api
            setData({ email, instagram, link_map, open_hours, phone_number, photo, description, wa_link, instagram_link })
        }

        fetchData()
    }, [])

    if (lg) {
        return (
            <div className="flex flex-col gap-16 py-[4vw] bg-cream pt-8 overflow-hidden">
                <div className="flex w-[95%] text-white gap-4 m-auto h-full bg-cream">
                    <div className="p-[4vw] w-[60%] rounded-[2vw] bg-maroon">
                        <div className="flex flex-col gap-16">
                            <div className="flex justify-between items-center">
                                <div className="flex flex-col gap-8">
                                    <div className='w-[6vw] h-[6vw] '>
                                        <img src={logo} className='w-full object-cover'></img>
                                    </div>
                                    <div className='font-poppins w-[60%] text-[1vw]'>
                                        {data.description}
                                    </div>
                                </div>
                                <div className="flex flex-col text-[1.5vw] gap-4 font-luckiest">
                                    <div className=''>
                                        <a href='/'>home</a>
                                    </div>
                                    <div className=''>
                                        <a href='/about'>about</a>
                                    </div>
                                    <div className=''>
                                        <a href='/products'>product</a>
                                    </div>
                                    <div className='font-luckiest'>
                                        <a href={data.wa_link} target='_blank'>ORDER</a>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center text-white">
                                <div className='flex gap-4 '>
                                    <div className='font-luckiest'>
                                        COPYRIGHT
                                    </div>
                                    <div className='font-luckiest'>
                                        &copy;
                                    </div>
                                    <div className='font-luckiest'>
                                        BAKENTAKE
                                    </div>
                                </div>
                                <div className='flex gap-4'>
                                    <div className='font-luckiest'>
                                        TERMS OF SERVICE
                                    </div>
                                    <div className='font-luckiest'>
                                        |
                                    </div>
                                    <div className='font-luckiest'>
                                        PRIVACY POLICY
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-amber-200 w-[40%] h-[27vw] rounded-[2vw] border-maroon border-[4px] overflow-hidden">
                        <Swiper pagination={true} modules={[Pagination]} className="mySwiper w-full h-full">
                            {
                                data.photo && data.photo.length > 0 ? (
                                    data.photo.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={'https://api.bakentake.id' + image.url}
                                                className='w-full h-full object-cover'
                                                alt={`Image ${index + 1}`}
                                            />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p className="text-center">No images available</p>
                                )
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        )
    }

    if (md) {
        return (
            <div className='bg-cream flex flex-col'>
                <div className='flex flex-col justify-center items-center bg-maroon text-white gap-4 px-4 py-4'>
                    <div className='flex justify-start w-full'>
                        <div className='h-[20vw]'>
                            <img className='w-full h-full object-contain ' src={logo}></img>
                        </div>
                    </div>

                    <div className='flex flex-col gap-10'>
                        <div className='flex flex-col justify-center items-center gap-4'>
                            <div className='font-poppins text-[4vw]'>
                                {data.description}
                            </div>

                            <div className='flex justify-between items-center gap-8 font-luckiest w-full'>
                                <div className='text-[4vw]'>
                                    <a href='/'>home</a>
                                </div>
                                <div className='text-[4vw]'>
                                    <a href='/about'>about</a>
                                </div>
                                <div className='text-[4vw]'>
                                    <a href='/products'>product</a>
                                </div>
                                <div className='text-[4vw]'>
                                    <a href={data.wa_link} target='_blank'>ORDER</a>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col'>
                            <div className='font-luckiest text-[4vw] flex justify-start items-center gap-4'>
                                <div>
                                    copyright
                                </div>
                                <div className='text-[6vw]'>
                                    &copy;
                                </div>
                                <div>
                                    bakentake
                                </div>
                            </div>
                            <div className='font-luckiest text-[4vw] flex justify-start items-center gap-4'>
                                <div>
                                    terms of service
                                </div>
                                <div>
                                    |
                                </div>
                                <div>
                                    privacy policy
                                </div>
                            </div>
                        </div>

                        <div className='h-[80vw] w-[95vw] rounded-3xl overflow-hidden'>
                            <Swiper pagination={true} modules={[Pagination]} className="mySwiper w-full h-full">
                                {
                                    data.photo && data.photo.length > 0 ? (
                                        data.photo.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={'https://api.bakentake.id' + image.url}
                                                    className='w-full h-full object-cover'
                                                    alt={`Image ${index + 1}`}
                                                />
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        <p className="text-center">No images available</p>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import background from '../../assets/bg_home.png'
import roti_pisang from '../../assets/roti_pisang.png'
import '../../utilities/home.css'
import circle from '../../assets/circle.png'
import rectangle from '../../assets/rectangle.png'
import heart from '../../assets/heart.png'
import diamond from '../../assets/diamond.png'
import { UseMediaQueries } from '../../utilities/hook'
import { motion, useInView } from "framer-motion"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

export default function Introduction({ title, description, photo, wa_link }) {

    const { md, lg } = UseMediaQueries()

    if (lg) {
        return (
            <div>
                <div className='flex justify-center items-center mt-[2vh] mx-20 h-full'>
                    <motion.div
                        initial={{ left: '-100%', opacity: 0 }}
                        animate={{ left: '0%', opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeInOut" }} className='flex flex-col relative z-20 gap-6 '>
                        <div className='font-luckiest text-[6vw] text-choco leading-none'>
                            {title}
                        </div>
                        <div className='font-poppins w-[65%] text-[1.1vw]'>
                            {description}
                        </div>
                        <div>
                            <button className='bg-red text-white font-luckiest w-auto px-8 py-1 rounded-full shop-now-button text-3xl'>
                                <a href={wa_link} target='_blank'>SHOP NOW</a>
                            </button>
                        </div>
                    </motion.div>
                    <div className='w-full h-full relative z-20 flex flex-col'>
                        <motion.div
                            initial={{ right: '-100%', opacity: 0 }}
                            animate={{ right: '0%', opacity: 1 }}
                            transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} className='relative z-30 bg-sky-200 h-0 flex justify-around items-end'>
                            <motion.img initial={{ top: '3vw' }} animate={{ top: '1vw' }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut" }} className='relative z-30 top-[3vw] right-[2vw] w-[3vw] h-[3vw]' src={heart}></motion.img>
                            <motion.img initial={{ left: '4vw' }} animate={{ left: '1vw' }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut" }} className='relative z-30 top-[6vw] left-[4vw] w-[5vw] h-[7vw]' src={diamond}></motion.img>
                        </motion.div>
                        <motion.div
                            initial={{ right: '-100%', opacity: 0 }}
                            animate={{ right: '0%', opacity: 1 }}
                            transition={{ duration: 1, ease: "easeInOut" }}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.5, ease: "easeInOut" }
                            }}
                            className='relative z-20 border-[1vw] border-white rounded-full w-[40vw] h-[40vw] overflow-hidden'>
                            <Swiper
                                autoplay={{ delay: 5000, disableOnInteraction: false }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper w-full h-full "
                            >
                                {photo && photo.length > 0 ? (
                                    photo.map((image, index) => (
                                        <SwiperSlide key={index} className='relative w-full h-full'>
                                            <img
                                                alt={`Image ${index + 1}`}
                                                src={'https://api.bakentake.id' + image.url}
                                                className='absolute inset-0 w-full h-full object-cover' // Positioning adjustments
                                            />
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p className="text-center">No images available</p>
                                )}
                            </Swiper>
                        </motion.div>


                        <motion.div
                            initial={{ right: '-100%', opacity: 0 }}
                            animate={{ right: '0%', opacity: 1 }}
                            transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} className='relative z-30 h-0 flex justify-around items-end'>
                            <motion.img initial={{ scale: 1 }} animate={{ scale: 1.2 }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut" }} className='relative z-30 top-[3vw] right-[4vw] w-[8vw] h-[8vw]' src={rectangle}></motion.img>
                            <motion.img initial={{ top: 0 }} animate={{ top: 2 }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut" }} className='relative z-30 top-0 left-[2vw] w-[5vw] h-[3vw]' src={circle}></motion.img>
                        </motion.div>
                    </div>
                    <div className='flex flex-col'>

                    </div>
                </div>
                <img src={background} className='h-[50vw] w-[100vw] z-10 absolute top-0'></img>
            </div>
        )
    }

    if (md) {
        return (
            <div>
                <div className='flex justify-center items-center mt-[2vh] mx-5'>

                    <div className='flex flex-col relative z-20 gap-8 justify-center items-center'>
                        <motion.div
                            initial={{ right: '-100%', opacity: 0 }}
                            animate={{ right: '0%', opacity: 1 }}
                            transition={{ duration: 1, ease: "easeInOut" }}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                            }} className='relative w-full h-full flex justify-center items-center flex-col -mt-10'>
                            <div className='flex w-full justify-center items-center'>
                                <motion.img initial={{ top: '15vw' }} animate={{ top: '17vw' }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut" }} className='relative z-30 top-[15vw] right-[25vw] w-[8vw] h-[8vw]' src={heart}></motion.img>
                                <motion.img initial={{ top: '13vw' }} animate={{ top: '15vw' }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut", delay: 0.4 }} className='relative z-30 top-[15vw] left-[25vw] w-[12vw] h-[16vw]' src={diamond}></motion.img>
                            </div>
                            <div className='relative z-20 border-[2vw] border-white h-[90vw] w-[90vw] rounded-full overflow-hidden mx-auto'>
                                <Swiper
                                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                                    modules={[Pagination, Autoplay]}
                                    className="mySwiper w-full h-full"
                                >
                                    {photo && photo.length > 0 ? (
                                        photo.map((image, index) => (
                                            <SwiperSlide key={index} className='flex justify-center items-center'>
                                                <img
                                                    alt={`Image ${index + 1}`}
                                                    src={'https://api.bakentake.id' + image.url}
                                                    className='w-full h-full object-cover'
                                                    style={{
                                                        maxWidth: '150%',
                                                        maxHeight: '150%',
                                                        objectFit: 'cover',
                                                        position: 'absolute',
                                                    }}
                                                />
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        <p className="text-center">No images available</p>
                                    )}
                                </Swiper>
                            </div>

                            <div className='flex w-full h-full justify-center items-center'>
                                <motion.img initial={{ bottom: '6vw' }} animate={{ bottom: '10vw' }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut" }} className='relative bottom-[6vw] right-[14vw] z-20 w-[22w] h-[22vw]' src={rectangle}></motion.img>
                                <motion.img initial={{ left: '15vw' }} animate={{ left: '18vw' }} transition={{ repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut" }} className='relative bottom-[4vw] z-20  left-[15vw] w-[14vw] h-[10vw]' src={circle}></motion.img>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ left: '-100%', opacity: 0 }}
                            animate={{ left: '0%', opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeInOut" }} className='relative font-luckiest text-7xl text-choco  w-full'>
                            {title}
                        </motion.div>
                        <motion.div
                            initial={{ left: '-100%', opacity: 0 }}
                            animate={{ left: '0%', opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeInOut" }} className='relative font-poppins w-full text-lg '>
                            {description}
                        </motion.div>
                        <motion.div
                            initial={{ left: '-100%', opacity: 0 }}
                            animate={{ left: '0%', opacity: 1 }}
                            transition={{ duration: 0.8, ease: "easeInOut" }} className='w-full flex justify-start relative'>
                            <button className='bg-red text-white font-luckiest w-auto px-16 py-4 rounded-full shop-now-button text-2xl'>
                                <a href={wa_link} target='_blank'>SHOP NOW</a>
                            </button>
                        </motion.div>
                    </div>
                </div>
                <img src={background} className='h-[70vw] w-[100vw] z-10 absolute top-0'></img>
            </div>
        )
    }
}

{/* <div className='relative z-30 bg-sky-200 top-0'>
                                    <img className='relative z-20 top-[8vw] right-[35vw] w-[3vw] h-[3vw]' src={heart}></img>
                                    <img className='relative z-20 top-[8vw] right-[7vw] w-[5vw] h-[7vw]' src={diamond}></img>
                                    <img className='relative z-20 top-[43vw] right-[35vw] w-[8vw] h-[8vw]' src={rectangle}></img>
                                    <img className='relative z-20 top-[46vw] right-[11vw] w-[5vw] h-[3vw]' src={circle}></img>
                                </div> */}
import diamond_blue from '../../assets/diamond_blue.png'
import diamond_yellow from '../../assets/diamond_yellow.png'
import circle_orange from '../../assets/circle_orange.png'
import { HomeProducts } from '../../components/Products'
import roti_pisang from '../../assets/roti_pisang.png'
import { UseMediaQueries } from '../../utilities/hook'
import { useInView, motion } from 'framer-motion'
import { useRef } from 'react'


export default function Products({ products, wa_link }){
 
    const { md, lg } = UseMediaQueries()
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    if(lg){
        return (
            <div className="flex justify-center items-center flex-col gap-4 h-full">
                <motion.div
                    ref={ref}
                    initial={{ right: '-100vw', opacity: 0 }}
                    animate={isInView ? { right: '0vw', opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} 
                    className="text-red text-[2vw] decoration-4 font-poppins w-full text-center relative">
                        Freshly baked delights made with love
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ left: '-100vw', opacity: 0 }}
                    animate={isInView ? { left: '0vw', opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} className="flex justify-center items-center w-[100vw] flex-col relative">
                    <div className="font-luckiest text-blue text-[5vw] leading-none">
                        LOOK AT OUR PRODUCTS
                    </div>
                    <div className='flex  justify-start w-[100%]'>
                        <motion.img initial={{ bottom: '6vw' }} animate={{ bottom: '5vw' }} transition={{repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut"}} className='relative left-[3vw] bottom-[6vw] w-[3vw] h-full' src={diamond_yellow}></motion.img>
                        <motion.img initial={{ bottom: '5vw' }} animate={{ bottom: '4vw' }} transition={{repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut", delay: 1}} className='relative left-[3.1vw]  bottom-[4vw] w-[2vw] h-full' src={diamond_blue}></motion.img>
                        <motion.img initial={{ bottom: '6vw' }} animate={{ bottom: '5vw' }} transition={{repeat: Infinity, repeatType: 'mirror', duration: 2, ease: "easeInOut"}} className='relative left-[2.5vw] bottom-[6vw] w-[1.2vw] h-full' src={circle_orange}></motion.img>
                    </div>
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} 
                    className='flex justify-around items-center w-full'>
                    <HomeProducts photo={products[0].photo} name={products[0].name} wa_link={wa_link}/>
                    <HomeProducts photo={products[1].photo} name={products[1].name} wa_link={wa_link} />
                    <HomeProducts photo={products[2].photo} name={products[2].name} wa_link={wa_link} />
                </motion.div>
                <motion.div 
                whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                }}
                className='flex justify-center items-center mt-8'>
                    <button className='font-luckiest px-10 py-2 bg-red rounded-full text-white text-2xl'>VIEW MORE</button>
                </motion.div>
            </div>
        )
    }

    if(md){
        return (
            <div className="flex justify-center items-center flex-col gap-4">
                <div  
                    className="text-red text-[2.8vw] decoration-4 font-poppins relative">
                        Freshly baked delights made with love
                </div>
                <div className='flex justify-start w-[100%] '>
                    <img className='relative left-[7vw] bottom-[9vw] w-[4vw] h-full' src={diamond_yellow}></img>
                    <img className='relative left-[7vw]  bottom-[6vw] w-[3vw] h-full' src={diamond_blue}></img>
                    <img className='relative left-[5.5vw] bottom-[8vw] w-[1.5vw] h-full' src={circle_orange}></img>
                </div>
                <div className="flex justify-center items-center w-[90%] flex-col -mt-10">
                    <motion.div
                    ref={ref}
                    initial={{ scale: 1.1 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut", repeat: Infinity, repeatType: 'mirror' }} className="font-luckiest text-blue text-[12vw] text-center leading-none">
                        LOOK AT OUR PRODUCTS
                    </motion.div>
                </div>
                <div className='flex flex-col justify-center items-center mx-8 gap-8'>
                    <HomeProducts photo={products[0].photo} name={products[0].name} wa_link={wa_link} />
                    <HomeProducts photo={products[1].photo} name={products[1].name} wa_link={wa_link} />
                    <HomeProducts photo={products[2].photo} name={products[2].name} wa_link={wa_link} />
                </div>
                <div className='flex justify-center items-center mt-8'>
                    <button className='font-luckiest px-10 py-2 bg-red rounded-full text-white text-2xl'>VIEW MORE</button>
                </div>
            </div>
        )
    }
}
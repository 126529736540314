
import { useEffect, useState } from "react"
import Footer from "../../components/Footer"
import NavigationBar from "../../components/Navbar"
import Introduction from "./1_Introduction"
import Products from "./2_Products"
import { Background } from "./3_Background"
import BakeGallery from "./4_BakeGallery"
import Information from "./5_Information"
import { apiHandler, apiHandlerCustom } from "../../utilities/apiHandler"

export default function Home(){
    
    const [data, setData] = useState({})
    const [topPhoto, setTopPhoto] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
            const api = await apiHandler('home')
            const footerApi = await apiHandler('footer')
            const custom = await apiHandlerCustom("products?populate=*&_sort=createdAt:desc&pagination[start]=0&pagination[limit]=3")
            console.log(api)
            const { background_description, background_title, background_photo, description, gallery, photo, title } = api
            const { wa_link } = footerApi
            setData({ background_description, background_title, background_photo, description, gallery, photo, title, wa_link })
            setTopPhoto(custom)
        }

        fetchData()
    }, [])

    return (
        <div className="flex flex-col max-w-[100vw] overflow-y-hidden">
            <NavigationBar/>
            {
                data.background_photo ? 
                <Introduction title={data.title} description={data.description} photo={data.photo} wa_link={data.wa_link}/> : <></>
            }
            <div className="w-[100vw] h-[10vh] "></div>
            {
                topPhoto ? (
                    <Products products={topPhoto} wa_link={data.wa_link} /> ) : (<></>)
            }
            {
                data.background_photo ? 
                <Background title={data.background_title} description={data.description} photo={data.background_photo}/> : <></>
            }
            {
                data.gallery ? 
                <BakeGallery photo={data.gallery}/> : <></>
            }
            <Information/>
            <Footer/>
        </div>
    )
}
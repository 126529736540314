import blue_wave from '../../assets/blue_wave_2.png'
import { UseMediaQueries } from '../../utilities/hook'

export default function Description(){

    const { md, lg } = UseMediaQueries()

    if(lg){
        return (
            <div className="flex flex-col mt-[4vw] w-full justify-center items-center overflow-hidden">
                <div className='w-full'>
                    <img src={blue_wave} className="w-full" alt="Blue Wave" />
                </div>
                <div className="flex justify-evenly items-start bg-light_blue py-[4vw] w-full">
                    <div className="font-luckiest text-[4vw] text-white w-[50%] leading-normal ">
                        BAKE N TAKE IS YOUR GO-TO DESTINATION FOR <span className="bg-yellow text-brown">FRESHLY BAKED</span> DELIGHTS THAT BRING WARMTH AND JOY TO EVERY BITE
                    </div>
                    <div className='flex flex-col justify-start  w-[30%]'>
                        <div className="text-yellow font-luckiest text-[2.3vw] w-full leading-normal ">
                            HOURS OF OPERATIONS: 
                        </div>
                        <div className="text-yellow font-luckiest text-[2.3vw] w-full leading-normal pt-4">
                            Monday - Thursday
                        </div>
                        <div className="text-yellow font-luckiest text-[2.3vw] w-full leading-normal ">
                            09.00 - 19.00
                        </div>
                        <div className="text-yellow font-luckiest text-[2.3vw] w-full leading-normal ">
                            Friday - Saturday
                        </div>
                        <div className="text-yellow font-luckiest text-[2.3vw] w-full leading-normal ">
                            09.00 - 21.00
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(md){
        return (
            <div className="flex flex-col py-4">
                <div>
                    <img src={blue_wave}></img>
                </div>
                <div className="flex flex-col justify-evenly items-start bg-light_blue gap-4 px-4 py-4">
                    <div className="font-luckiest text-[9vw] text-white w-[80%] leading-normal">
                        BAKE N TAKE IS YOUR GO-TO DESTINATION FOR <span className="bg-yellow text-brown">FRESHLY BAKED</span> DELIGHTS THAT BRING WARMTH AND JOY TO EVERY BITE
                    </div>
                    <div className="text-yellow font-luckiest text-4xl w-full leading-normal flex flex-col justify-start">
                        HOURS OF OPERATIONS:
                        <div className='pt-4'>
                            Monday - Thursday
                        </div>
                        <div>
                            09.00 - 19.00
                        </div>
                        <div className='pt-4'>
                            Friday - Saturday
                        </div>
                        <div>
                            09.00 - 21.00
                        </div>
                    </div>

                    
                </div>
            </div>
        )
    }
}
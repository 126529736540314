import roti_pisang from '../../assets/roti_pisang.png'
import { UseMediaQueries } from '../../utilities/hook'
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

export default function Showcase({ photo }) {

    const { md, lg } = UseMediaQueries()

    if (lg) {
        return (
            <motion.div
                initial={{ left: '-100vw', opacity: 0 }}
                animate={{ left: 0, opacity: 1 }}
                transition={{ duration: 0.7, ease: 'easeInOut' }}
                className='w-full h-full flex justify-center items-center overflow-hidden relative'>
                <div className='w-[95%] h-[40vw]'>

                    <Swiper autoplay={{ delay: 5000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                        {
                            photo && photo.length > 0 ? (
                                photo.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img alt={`Image ${index + 1}`} src={'https://api.bakentake.id' + image.url} className='object-cover w-full h-full rounded-[4vw] overflow-hidden'></img>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <p className="text-center">No images available</p>
                            )
                        }
                    </Swiper>
                </div>
            </motion.div >
        )
    }

    if (md) {
        return (
            <motion.div
                initial={{ left: '-100vw', opacity: 0 }}
                animate={{ left: 0, opacity: 1 }}
                transition={{ duration: 0.7, ease: 'easeInOut' }} className='h-[60vw]  m-auto rounded-[4vw] overflow-hidden mx-2 relative'>
                <Swiper autoplay={{ delay: 5000, disableOnInteraction: false }} modules={[Pagination, Autoplay]} className="mySwiper w-full h-full">
                    {
                        photo && photo.length > 0 ? (
                            photo.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img alt={`Image ${index + 1}`} src={'https://api.bakentake.id' + image.url} className='object-cover w-full h-full rounded-[4vw] overflow-hidden'></img>
                                </SwiperSlide>
                            ))
                        ) : (
                            <p className="text-center">No images available</p>
                        )
                    }
                </Swiper>
            </motion.div >
        )
    }
}
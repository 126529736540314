import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Products from "./pages/Product/Products";
import Snowfall from "react-snowfall";

function App() {
  return (
    <BrowserRouter>
      <Snowfall
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: 100,
        }}
        snowflakeCount={200}
        speed={[0.5, 4.5]}
        wind={[-0.5, 2]}
        radius={[1, 3]}
        rotationSpeed={[-1.0, 1.0]}
        color="#b7c1eb"
      />
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import Marquee from "react-fast-marquee";
import bg_yellow from '../../assets/bg_yellow.png'
import roti_kiri from '../../assets/roti_info_1.png'
import roti_kanan from '../../assets/roti_info_2.png'
import { UseMediaQueries } from "../../utilities/hook";

export default function Information(){

    const { md, lg } = UseMediaQueries()

    if(lg){
        return (
            <div className="flex flex-col w-full"> 
                <Marquee gradient={false} speed={50} className="font-luckiest text-white bg-blue text-5xl py-8">
                    Indulge in the finest pastries and breads &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                </Marquee>
                <div className="flex items-center justify-evenly" style={{ backgroundImage: `url(${bg_yellow})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="w-[20vw] ">
                        <img src={roti_kiri} className="w-full h-full"></img>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <div className="text-brown font-luckiest text-[4vw]  text-center w-[80%] leading-none">
                            DON'T MISS OUT UP TO 10% DISC
                        </div>
                        <button className="font-luckiest text-white bg-red rounded-3xl w-[25%] py-2 text-[1.5vw]">
                            SHOP NOW
                        </button>
                    </div>
                    <div className="w-[20vw]">
                        <img src={roti_kanan} className="w-full h-full"></img>
                    </div>
                </div>
            </div>
        )
    }


    if(md){
        return (
            <div className="flex flex-col w-full"> 
                <Marquee gradient={false} speed={50} className="font-luckiest text-white bg-blue text-5xl py-8">
                    Indulge in the finest pastries and breads &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Marquee>
                <div className="flex items-center justify-evenly py-8 w-full" style={{ backgroundImage: `url(${bg_yellow})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="w-[40vw] flex">
                        <img src={roti_kiri} className="w-full h-full"></img>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2">
                        <div className="text-brown font-luckiest text-[7vw] text-center ">
                            DON'T MISS OUT UP TO 10% DISC
                        </div>
                        <button className="font-luckiest text-white bg-red rounded-3xl py-2 px-8 text-[3vw]">
                            SHOP NOW
                        </button>
                    </div>
                    <div className="w-[40vw] ">
                        <img src={roti_kanan} className="w-full h-full object-center"></img>
                    </div>
                </div>
            </div>
        )
    }
}
import roti_pisang from '../../assets/roti_pisang.png'
import blue_wave from '../../assets/blue_wave_2.png'
import { UseMediaQueries } from '../../utilities/hook'
import { useRef } from 'react';
import { useInView, motion } from 'framer-motion';


export default function Follow({ photo, instagram }) {

    const { md, lg } = UseMediaQueries()
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });


    if (lg) {
        return (
            <div>
                <div className="flex items-center justify-center flex-col bg-cream w-[100vw] overflow-hidden pb-[4vw]">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <div className="font-poppins text-blue text-[5vw] font-bold">
                            FOLLOW ALONG FOR UPDATES
                        </div>
                        <div className="font-luckiest text-brown text-[4vw]">
                            {instagram}
                        </div>
                    </div>
                    <div className="flex items-center justify-center bg-cream relative ">
                        <motion.div
                            ref={ref}
                            initial={{ left: '-5vw', opacity: 0 }}
                            animate={isInView ? { left: '-10vw', opacity: 1 } : {}}
                            transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }}
                            className="relative -left-[10vw] h-[45vw] w-[40vw] bg-red rounded-[2vw] overflow-hidden z-20">
                            {
                                photo ?
                                    <img src={'https://api.bakentake.id' + photo[0].url} className='object-cover w-full h-full'></img> : <></>
                            }
                        </motion.div>
                        <div className="w-[35vw] rounded-[2vw] overflow-hidden z-20">
                            {
                                photo ?
                                    <img src={'https://api.bakentake.id' + photo[1].url} className='object-cover w-full h-full'></img> : <></>
                            }
                        </div>
                        <motion.div
                            ref={ref}
                            initial={{ right: '-5vw', opacity: 0 }}
                            animate={isInView ? { right: '-10vw', opacity: 1 } : {}}
                            transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }}
                            className="relative -right-[10vw] h-[45vw] w-[40vw] bg-red rounded-[2vw] overflow-hidden z-20">
                            {
                                photo ?
                                    <img src={'https://api.bakentake.id' + photo[2].url} className='object-cover w-full h-full'></img> : <></>
                            }
                        </motion.div>

                    </div>
                </div>
                <div className="relative bottom-[12vw] z-10 h-0">
                    <img src={blue_wave} className='object-cover w-[100vw] h-[10vw]'></img>
                </div>
            </div>
        )
    }

    if (md) {
        return (
            <div className='bg-cream  mt-4' >
                <div className="flex items-center justify-center flex-col w-full overflow-hidden pt-4">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <div className="font-poppins text-blue text-[7vw] font-bold w-[60%] text-center leading-none">
                            FOLLOW ALONG FOR UPDATES
                        </div>
                        <div className="font-luckiest text-brown text-center text-[8vw] w-full leading-none mb-4">
                            {instagram}
                        </div>
                    </div>
                    <div className="flex items-center justify-center relative  pb-4" style={{ backgroundImage: `url(${blue_wave})`, backgroundSize: '100% 40%', backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', objectFit: 'fill' }}>
                        <div className="relative -left-[5vw] h-[70vw] w-[40vw] bg-red rounded-[2vw] overflow-hidden z-20">
                            {
                                photo ?
                                    <img src={'https://api.bakentake.id' + photo[0].url} className='object-cover w-full h-full'></img> : <></>
                            }
                        </div>
                        <div className="w-[45vw] bg-red rounded-[2vw] overflow-hidden z-20">
                            {
                                photo ?
                                    <img src={'https://api.bakentake.id' + photo[1].url} className='object-cover w-full h-full'></img> : <></>
                            }
                        </div>
                        <div className="relative -right-[5vw] h-[70vw] w-[40vw] bg-red rounded-[2vw] overflow-hidden z-20">
                            {
                                photo ?
                                    <img src={'https://api.bakentake.id' + photo[2].url} className='object-cover w-full h-full'></img> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import blue_wave from '../../assets/blue_wave.png'
import sunny from '../../assets/sunny.png'
import roti_pisang from '../../assets/roti_pisang.png'
import { UseMediaQueries } from '../../utilities/hook'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'
import '../../utilities/home.css'

export function Background({ title, description, photo }) {

    const { md, lg } = UseMediaQueries()
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    if (lg) {
        return (
            <div className="flex flex-col justify-center items-center mt-10 gap-10 py-10" style={{ backgroundImage: `url(${blue_wave})`, backgroundSize: '70% 50%', backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', objectFit: 'cover' }}>
                <motion.div
                    ref={ref}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }}
                    className="text-[3vw] font-luckiest text-brown w-[80%] text-center leading-none">
                    {title}
                </motion.div>
                <div className='flex justify-center items-center w-full h-full gap-[6vw]'>
                    <div className='w-[50%] flex flex-col justify-end items-end gap-[8vw]'>
                        <div className='flex justify-end items-end'>
                            <div className='h-[40vw] w-[40vw] shadow-drop-2-center rounded-3xl' >
                                <img className='w-full h-full object-cover rounded-3xl' src={'https://api.bakentake.id' + photo[0].url}></img>
                            </div>
                        </div>
                        <div className='w-full flex flex-col gap-4 items-end'>
                            <div className='w-[60%] font-poppins text-[1.2vw] text-white'>
                                {description}
                            </div>
                            <div className='flex justify-start items-start w-[60%]'>
                                <motion.button
                                    whileHover={{
                                        scale: 1.1,
                                        transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                                    }}
                                    className='bg-yellow px-6 py-2 font-luckiest text-blue rounded-3xl w-fit text-[1.3vw]'>
                                    about us
                                </motion.button>
                            </div>
                        </div>
                    </div>

                    <div className='w-[50%] flex flex-col justify-start items-start h-full gap-[4vw]'>
                        <div className='w-full h-full flex justify-end items-end'>
                            <div className='w-[10vw] h-[10vw] mr-[5vw]'>
                                <img className='w-full h-full object-cover' src={sunny}></img>
                            </div>
                        </div>
                        <div className='h-[50vw] w-[40vw] shadow-drop-2-center rounded-3xl'>
                            <img className='w-full h-full object-cover rounded-3xl' src={'https://api.bakentake.id' + photo[1].url}></img>
                        </div>
                    </div>
                </div >

            </div >
        )
    }

    if (md) {
        return (
            <div className='' style={{ backgroundImage: `url(${blue_wave})`, backgroundSize: '180% 50%', backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', objectFit: 'fill' }}>
                {/* <div className='w-full h-full'> */}

                <div className="flex flex-col justify-center items-center mt-10 gap-10 mx-8 mb-10" >
                    <div className="text-4xl font-luckiest text-brown w-full text-center">{title}</div>
                    <div className='flex flex-col justify-center items-center gap-8'>
                        <div className='w-full h-[90vw] bg-red rounded-3xl'>
                            <img className='w-full h-full object-cover rounded-3xl' src={'https://api.bakentake.id' + photo[0].url}></img>
                        </div>
                        <div className='w-full h-[120vw] bg-red rounded-3xl'>
                            <img className='w-full h-full object-cover rounded-3xl' src={'https://api.bakentake.id' + photo[1].url}></img>
                        </div>
                    </div>
                    <div className='text-white w-full text-xl font-poppins'>
                        {description}
                    </div>
                    <div className='flex justify-start items-center w-full'>
                        <button className='font-luckiest text-blue text-2xl px-12 py-4 bg-yellow rounded-full leading-none'>ABOUT US</button>
                    </div>
                </div >
                {/* <img src={blue_wave} className='h-[50vw] w-[100vw] z-10 absolute bg-red'></img> */}
            </div >
        )
    }
}
import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import NavigationBar from "../../components/Navbar";
import Image from "./1_Image";
import ProductLists from "./2_Product_List";
import { apiHandler, apiHandlerCustom } from "../../utilities/apiHandler";

export default function Products() {

    const [data, setData] = useState(null);
    const [footer, setFooter] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const api = await apiHandlerCustom('products?populate=*&pagination[page]=1&pagination[pageSize]=1000');
            const footer = await apiHandler('footer')
            const { wa_link } = footer
            setFooter({ wa_link })
            setData(api);
        };

        fetchData();
    }, []);

    return (
        <div className="flex flex-col">
            <NavigationBar />
            <Image />
            {
                data ? (
                    <ProductLists data={data} wa_link={footer.wa_link} />
                ) : (
                    <div>Loading...</div> // Optionally show a loading state
                )
            }
            <Footer />
        </div>
    );
}

import Marquee from 'react-fast-marquee'
import roti_pisang from '../../assets/roti_pisang.png'
import { UseMediaQueries } from '../../utilities/hook'
import { motion } from 'framer-motion';

export default function Image(){
    
    const { md, lg } = UseMediaQueries()

    if(lg){
        return (
            <div>
                <div className="flex flex-col gap-[4vw] font-luckiest justify-center items-center w-full h-[100vh] bg-cover bg-center" style={{ backgroundImage: `url(${roti_pisang})` }}>
                    <motion.div
                        initial={{ top: '-5vw', opacity: 0 }}
                        animate={{ top: 0, opacity: 1 }}
                        transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }} 
                        className="relative text-brown bg-white px-8 py-2 text-4xl">
                        OUR PRODUCTS
                    </motion.div>
                    <div className="font-luckiest text-6xl flex flex-col justify-center items-center">
                        <motion.div
                        initial={{ bottom: '-5vw', opacity: 0 }}
                        animate={{ bottom: 0, opacity: 1 }}
                        transition={{ duration: 0.7, ease: "easeInOut" }}
                        className="text-brown bg-yellow px-8 py-2 relative">
                            BITE INTO HAPPINESS,
                        </motion.div>
                        <motion.div
                        initial={{ bottom: '-5vw', opacity: 0 }}
                        animate={{ bottom: 0, opacity: 1 }}
                        transition={{ duration: 0.7,delay: 0.3, ease: "easeInOut" }}
                        className="px-8 py-2 text-white bg-brown relative">
                            ONE TREAT AT A TIME
                        </motion.div>
                    </div>
                </div>
                <Marquee gradient={false} speed={50} className="font-luckiest text-white bg-blue text-5xl py-8">
                    Indulge in the finest pastries and breads &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Marquee>
            </div>
        )
    }

    if(md){
        return (
            <div>
                <div className="flex flex-col gap-[4vw] font-luckiest justify-center items-center w-full h-[100vh] bg-cover bg-center" style={{ backgroundImage: `url(${roti_pisang})` }}>
                    <div className="text-brown bg-white px-8 py-2 text-[6vw]">
                        OUR PRODUCTS
                    </div>
                    <div className="font-luckiest  flex flex-col justify-center items-center text-center gap-2">
                        <div className="text-brown bg-yellow px-8 py-2 text-[8vw]">
                            BITE INTO HAPPINESS,
                        </div>
                        <div className="px-8 py-2 text-white bg-brown text-[8vw]">
                            ONE TREAT AT A TIME
                        </div>
                    </div>
                </div>
                <Marquee gradient={false} speed={50} className="font-luckiest text-white bg-blue text-5xl py-8">
                    Indulge in the finest pastries and breads &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Marquee>
            </div>
        )
    }
}
 
import roti_pisang from '../../assets/roti_pisang.png'
import diamond_blue from '../../assets/diamond_blue.png'
import diamond_yellow from '../../assets/diamond_yellow.png'
import circle_orange from '../../assets/circle_orange.png'
import { UseMediaQueries } from '../../utilities/hook'
import { useRef } from 'react'
import { useInView, motion } from 'framer-motion'

export default function BakeGallery({ photo }) {

    const { md, lg } = UseMediaQueries()
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    if (lg) {
        return (
            <div className='flex flex-col justify-center items-center my-[4vw]'>
                <motion.div
                    ref={ref}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={isInView ? { scale: 1, opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3, ease: "easeInOut" }}
                    className='flex flex-col w-full justify-center items-center'>
                    <div className='font-luckiest text-blue text-[5vw] leading-none'>
                        BAKE GALLERY
                    </div>
                    <div className='flex  justify-start w-[100%]'>
                        <img className='relative left-10 bottom-20 w-[3vw] h-full' src={diamond_yellow}></img>
                        <img className='relative left-10  bottom-8 w-[2vw] h-full' src={diamond_blue}></img>
                        <img className='relative left-5 bottom-20 w-[1.2vw] h-full' src={circle_orange}></img>
                    </div>
                </motion.div>
                <div class="flex  gap-[1vw] w-full px-[4vw]">
                    <div class="flex-grow-[3] w-[70%]  flex flex-col gap-[1vw] h-full">
                        <div className="flex gap-[1vw] h-full">
                            <motion.div
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                                }} className=" w-[50%] h-[20vw] ">
                                <img src={'https://api.bakentake.id' + photo[0].url} className='object-cover w-full h-full rounded-3xl'></img>
                            </motion.div>
                            <motion.div
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                                }} className=" w-[50%] h-[20vw] rounded-3xl">
                                <img src={'https://api.bakentake.id' + photo[1].url} className='object-cover w-full h-full rounded-3xl'></img>
                            </motion.div>
                        </div>
                        <div className="h-full">
                            <motion.div
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                                }} className=" w-[100%] h-[28vw] rounded-3xl">
                                <img src={'https://api.bakentake.id' + photo[2].url} className='object-cover w-full h-full rounded-3xl'></img>
                            </motion.div>
                        </div>
                    </div >
                    <div class="flex-grow w-[30%]  flex flex-col gap-[1vw]">
                        <motion.div
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                            }} className=" w-[100%] h-[20vw] rounded-3xl">
                            <img src={'https://api.bakentake.id' + photo[3].url} className='object-cover w-full h-full rounded-3xl'></img>
                        </motion.div>
                        <motion.div
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.5, ease: "easeInOut" } // Durasi animasi hover 0.5 detik
                            }} className=" w-[100%] h-[28vw] rounded-3xl">
                            <img src={'https://api.bakentake.id' + photo[4].url} className='object-cover w-full h-full rounded-3xl'></img>
                        </motion.div >
                    </div >
                </div >
            </div >
        )
    }

    if (md) {
        return (
            <div className='flex flex-col justify-center items-center my-[4vw]'>
                <div className='flex flex-col w-full justify-center items-center'>
                    <div className='font-luckiest text-blue text-[6vw]'>
                        BAKE GALLERY
                    </div>
                    <div className='flex justify-start w-[100%] '>
                        <img className='relative left-[10vw] bottom-[11vw] w-[6vw] h-full' src={diamond_yellow}></img>
                        <img className='relative left-[9vw]  bottom-[7vw] w-[5vw] h-full' src={diamond_blue}></img>
                        <img className='relative left-[7vw] bottom-[10vw] w-[3vw] h-full' src={circle_orange}></img>
                    </div>
                </div>
                <div class="flex  gap-[1vw] w-full px-[4vw]">
                    <div class="flex-grow-[3] w-[70%]  flex flex-col gap-[1vw] h-full">
                        <div className="flex gap-[1vw] h-full">
                            <div className=" w-[50%] h-[40vw] ">
                                <img src={'https://api.bakentake.id' + photo[0].url} className='object-cover w-full h-full rounded-3xl'></img>
                            </div>
                            <div className=" w-[50%] h-[40vw] rounded-3xl">
                                <img src={'https://api.bakentake.id' + photo[1].url} className='object-cover w-full h-full rounded-3xl'></img>
                            </div>
                        </div>
                        <div className="h-full">
                            <div className=" w-[100%] h-[48vw] rounded-3xl">
                                <img src={'https://api.bakentake.id' + photo[2].url} className='object-cover w-full h-full rounded-3xl'></img>
                            </div>
                        </div>
                    </div >

                </div >
            </div >
        )
    }
}
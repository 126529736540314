import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png';
import { UseMediaQueries } from '../utilities/hook';
import Hamburger from 'hamburger-react';
import '../utilities/navbar.css';
import { apiHandler } from '../utilities/apiHandler';

export default function NavigationBar() {
    const { md, lg } = UseMediaQueries();
    const [showSide, setShowSide] = useState(false);
    const [isExitSide, setExistSide] = useState(false);

    const [data, setData] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            const api = await apiHandler('footer')
            const { wa_link } = api
            setData({ wa_link })
        }

        fetchData()
    }, [])

    const handleToggleSide = () => {
        if (showSide) {
            setExistSide(true);
            setTimeout(() => {
                setShowSide(false);
                setExistSide(false);
                document.body.style.overflow = 'auto'; // Mengizinkan scroll kembali
            }, 450); // Waktu harus sesuai dengan durasi animasi keluar
        } else {
            setShowSide(true);
            document.body.style.overflow = 'hidden'; // Mencegah scroll
        }
    };

    if (lg) {
        return (
            <div className="flex justify-between items-center w-[100%] px-[8vw] py-4 bg-transparent relative z-20">
                <div className='max-w-[4vw] max-h-[4vw]'>
                    <img src={logo} alt="Logo"></img>
                </div>
                <div className='flex justify-between gap-10 items-center'>
                    <div className='border-b-4 border-transparent hover:border-red '>
                        <a href='/' className='pb-1 font-luckiest text-choco'>HOME</a>
                    </div>
                    <div className='border-b-4 border-transparent hover:border-red'>
                        <a href='/about' className='font-luckiest text-choco'>ABOUT</a>
                    </div>
                    <div className='border-b-4 border-transparent hover:border-red'>
                        <a href='/products' className='font-luckiest text-choco'>PRODUCT</a>
                    </div>
                    <a href='/' className='font-luckiest text-choco'>|</a>
                    <a href='/'>
                        <button className='font-luckiest bg-red-200 px-4 py-2 rounded-xl bg-choco text-white'>
                            <a href={data.wa_link} target='_blank'>ORDER ONLINE</a>
                        </button>
                    </a>
                </div>
            </div>
        );
    }

    if (md) {
        return (
            <div>
                <div className="flex justify-between items-center w-[100%] px-[8vw] py-4 bg-transparent relative z-20">
                    <div className='w-[14vw] h-[14vw]'>
                        <img src={logo} className='w-full h-full object-cover' alt="Logo"></img>
                    </div>
                    <div className="p-2 bg-red-500 rounded-full bg-brown">
                        <Hamburger toggled={false} toggle={handleToggleSide} color="#fff" rounded />
                    </div>
                </div>
                {showSide ?
                    <div
                        className={`absolute z-40 w-full h-[100vh] bg-cream top-0 left-0 overflow-hidden ${showSide ? 'slide-in-left' : ''} ${isExitSide ? 'slide-out-left' : ''}`}
                    >
                        <div className='w-full absolute flex justify-end items-end top-[4vw] right-[4vw]'>
                            <div className="p-2 bg-red-500 rounded-full bg-brown w-fit">
                                <Hamburger toggled={true} toggle={handleToggleSide} color="#fff" rounded />
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center font-luckiest text-brown gap-6 text-[8vw] h-full'>
                            <div>
                                <a href='/'>home</a>
                            </div>
                            <div>
                                <a href='/about'>about</a>
                            </div>
                            <div>
                                <a href='/products'>product</a>
                            </div>
                            <div className='bg-brown text-white px-4 py-2 rounded-3xl'>
                                <a href={data.wa_link} target='_blank'>ORDER ONLINE</a>
                            </div>
                        </div>
                    </div>
                    : <></>}
            </div>
        );
    }
}
